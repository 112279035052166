// gatsby-browser.js
import React from "react";
import { ThemeProvider } from "./src/contexts/ThemeContext";

// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";

// normalize CSS across browsers
import "./src/css/normalize.css";

// custom CSS styles
import "./src/css/global.css";

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};

export const onRouteUpdate = () => {
  document.body.classList.add("fadein");
};
