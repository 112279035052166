// src/contexts/ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [dark, setDark] = useState(false);

  useEffect(() => {
    // Ensure window is defined (so the code runs only in the browser)
    if (typeof window !== 'undefined') {
      const isDark = localStorage.getItem('darkMode') === 'true';
      setDark(isDark);
      updateBodyClass(isDark);
    }
  }, []);

  const toggleDark = () => {
    const isDark = !dark;
    // Ensure window is defined
    if (typeof window !== 'undefined') {
      localStorage.setItem('darkMode', JSON.stringify(isDark));
      updateBodyClass(isDark);
    }
    setDark(isDark);
  };

  const updateBodyClass = (isDark) => {
    // Ensure document is defined
    if (typeof document !== 'undefined') {
      if (isDark) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  };

  const contextValue = {
    dark,
    toggleDark,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
